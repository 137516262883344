import React, {createContext} from 'react' // on importe createContext qui servira à la création d'un ou plusieurs contextes

const {Provider, Consumer} = createContext({})
export const AppProvider = Provider
export const AppConsumer = Consumer

export const withApp = (Component) => (props) => {
  return <AppConsumer>{(store) => <Component {...props} {...store} />}</AppConsumer>
}

export default (props) => {
  return <AppProvider value={{...props.app}}>{props.children}</AppProvider>
}
