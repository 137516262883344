// session replay not yet supported for iframes : https://github.com/DataDog/browser-sdk/issues/2168
import {datadogRum} from '@datadog/browser-rum'

const applicationId = process.env.DD_APP_ID
const clientToken = process.env.DD_TOKEN

if (applicationId && clientToken) {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    service: 'wv2',
    env: process.env.APP_ENV,
    version: process.env.HEROKU_RELEASE_VERSION
      ? `2.0.0-${process.env.HEROKU_RELEASE_VERSION}`
      : '2.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.inmemori\.com/, /https:\/\/.*\.inmemori-dev\.com/],
    allowFallbackToLocalStorage: true // use localStorage instead of cookies to persist session data
  })

  datadogRum.startSessionReplayRecording()
} else {
  console.warn('datadogRum not initialized', {applicationId, clientToken})
}

export default datadogRum
