import 'isomorphic-fetch'
import {withQuery} from './utils'

export default function Api(options) {
  options = {
    baseUrl: '',
    query: {},
    creds: {},
    utms: true,
    append: {},
    ...options
  }
  this.options = options
}

Api.prototype.resultMiddleware = async function (result) {
  const appCtx = this.options.context
  const {data, context, status} = result

  if (context && appCtx) {
    context.features && appCtx.setFeatures && appCtx.setFeatures(context.features)
  }
}

Api.prototype.request = async function (method, path, params = {}, detailed = false) {
  const {baseUrl, creds, utms, headers, append, debug, mw, context} = this.options

  const jwt = params.jwt || creds.jwt
  const session = creds.session
  const fprint = creds.fprint
  const version = process.env.HEROKU_RELEASE_VERSION
    ? {im_web_build: process.env.HEROKU_RELEASE_VERSION}
    : {}
  const ui = context && context.lastUIRef && {im_web_ui: JSON.stringify(context.lastUIRef)}

  let query = {}
  let options = {}

  options.method = method
  options.headers = {
    'content-type': 'application/json',
    ...headers,
    ...params.headers,
    ...version,
    ...ui
  }

  if (params.body && options.headers['content-type'] == 'application/json') {
    if (options.method == 'get') query = {...query, ...params.body}
    else options.body = JSON.stringify(params.body)
  }

  if (append) query = {...query, ...append}
  if (Object.keys(utms).length) query = {...query, ...utms}

  if (jwt) options.headers.authorization = `JWT ${jwt}`
  if (session) options.headers.session = `${creds.session}`
  if (fprint) options.headers.fprint = `${creds.fprint}`

  const url = withQuery(`${baseUrl}${path}`, query)

  // apply middleware
  if (mw) mw(options)

  debug &&
    console.log(`[api:${method}] ${url.replace(baseUrl, '')}`, params.body || '')

  const result = await fetch(url, options)

  debug && console.log(`[result:api:${method}] ${url.replace(baseUrl, '')} status:${result.status}`)

  if (result.status == 406 && typeof window !== 'undefined') window.location.reload()

  const body = await result.json()

  this.resultMiddleware({...body, status: result.status})

  if (detailed) return {...body, status: result.status}
  return body.data
}

const methods = ['get', 'post', 'put', 'delete']

methods.forEach((method) => {
  Api.prototype[method] = async function (path, params = {}, detailed = false) {
    return await Api.prototype.request.call(this, method, path, params, detailed)
  }
})
