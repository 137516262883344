export const ToastDefault = ({label}) => {
  return (
    <div className='d-flex justify-content-start align-items-center snackbar-default'>
      <span>{label}</span>
    </div>
  )
}

export const ToastHelp = ({toaster, t, flow}) => {
  const Yes = () => {
    toaster.removeAll()
    flow.set('tab-share')
  }
  const No = () => {
    toaster.removeAll()
  }
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <span className='flex-shrink-1'>Vous avez besoin d&apos;aide ? </span>
      <button className='btn btn-sm btn-dark' onClick={() => No()}>
        {t(`common.no`)}
      </button>
      <button className='btn btn-sm btn-white' onClick={() => Yes()}>
        {t(`common.yes`)}
      </button>
    </div>
  )
}

export const ToastInviteManager = ({toaster, t, flow, id, tempValues, page, setTempValues}) => {
  const Invite = () => {
    toaster.removeAll()
    setTempValues({...tempValues, snackBarInviteManagerCta: true})
    flow.set('managers', {currentStep: 1, defaultMessage: t(`managers-add.snackbar`, page)})
  }

  const dismiss = () => {
    setTempValues({
      ...tempValues,
      snackBarInviteManagerCount: tempValues.snackBarInviteManagerCount
        ? tempValues.snackBarInviteManagerCount + 1
        : 1
    })
    toaster.removeAll()
  }
  return (
    <div className={`d-flex flex-wrap align-items-center snackbar snackbar-bnr snackbar-bnr-${id}`}>
      <div className='d-block'>{t(`snackbar.inviteManager.${id}`, page)}</div>
      <div className='d-flex align-items-right ml-auto'>
        <button
          className={`btn btn-sm snackbar-bnr-cta snackbar-bnr-${id}-cta pr-1 pl-1 ml-3`}
          onClick={() => Invite()}
        >
          {t(`snackbar.inviteManager.cta`)}
        </button>
        <button
          className={`btn btn-sm snackbar-dismiss fa fa-times snackbar-bnr-dismiss snackbar-bnr-${id}-dismiss ml-1 pl-1 pr-1`}
          style={{marginBottom: '-3px'}}
          onClick={dismiss}
        />
        <span className='d-none snackbar-bnr-key'>${id}</span>
      </div>
    </div>
  )
}
