import React, {Fragment} from 'react'

const fileName = (name) => {
  return `${name}.png`
}

const getPath = (size, path = '') => {
  return `${path}/${fileName(size)}`
}

const Iconize = ({path} = {}) => (
  <Fragment>
    {/* Favicon
    <link rel="icon" type="image/png" sizes="96x96" href={getPath('favicon-96x96', path)} />
    */}

    {/* Apple/Safari icon
      apple-touch-icon-precomposed.png
      apple-touch-icon-120x120-precomposed.png
      apple-touch-icon-120x120.png
    */}
    <link rel='apple-touch-icon' sizes='180x180' href={getPath('apple-touch-icon', path)} />

    {/* Square Windows tiles */}
    <meta name='msapplication-square70x70logo' content={getPath('ms-icon-70x70', path)} />
    <meta name='msapplication-square150x150logo' content={getPath('ms-icon-150x150', path)} />
    <meta name='msapplication-square310x310logo' content={getPath('ms-icon-310x310', path)} />
    {/* Rectangular Windows tile /}
    <meta name="msapplication-wide310x150logo" content={getPath('310x150', path)} />
    {/* Windows tile theme color
    <meta name="msapplication-TileColor" content="#2e2e2e" />*/}
  </Fragment>
)

export default Iconize

// _.each(sizes, s => console.log('-', fileName(s)))
// console.log('isSet', isSet('310x12'))
