const Tracker = ({slug, api}) => {
  if (!api) throw `Tracker missing args`

  const trackFunnel = (funnel, slugOveride) => async (step) => {
    try {
      if (!funnel || !step.key) throw 'funnel and step.key required'

      const body = {
        slug: slugOveride || slug,
        event: `funnel-${funnel}`,
        properties: {
          funnel: {
            step: step.key
          }
        }
      }

      await api.post(`/analytics/track`, {body})
    } catch (e) {
      console.log('tracker.funnel error', e)
    }
  }

  const trackFunnelOrder = (funnel, slugOveride, order) => async (step) => {
    try {
      if (!funnel || !step.key) throw 'funnel and step.key required'

      let body = {
        slug: slugOveride || slug,
        event: `funnel-${funnel}`,
        properties: {
          funnel: {
            step: step.key
          },
          ctx: {
            uiref: order.meta.uiref
          }
        }
      }

      await api.post(`/analytics/track`, {body})

      body = {
        ...body,
        centric: 'profile'
      }

      await api.post(`/analytics/track`, {body})
    } catch (e) {
      console.log('tracker.funnel error', e)
    }
  }

  return {
    funnel: trackFunnel,
    funnelOrder: trackFunnelOrder
  }
}

export default Tracker
