import {asArray, setErrors} from './utils'

const Profile = ({dsl = {}, api, tribe, profile, setProfile, panel, hasRole} = {}) => {
  dsl.relationshipCases = (t, page) => {
    const relationshipsOrder = t('relationships.order', true)
    let result = relationshipsOrder.default
    const {age, gender = 'm'} = page.defunct

    if (age && gender)
      for (let key in relationshipsOrder) {
        if (key === 'default') continue

        const s = asArray(key)
        const min = parseInt(s[0])
        const max = parseInt(s[1])

        if (age < min || age > max) continue

        result = relationshipsOrder[key]
      }

    return result
  }

  dsl.isComplete = () => {
    if (profile && hasRole('user')) {
      if (!profile.name || !profile.email || !tribe.relationship) return false
    }
    return true
  }

  dsl.updateProfile = async (body) => {
    try {
      const result = await api.put(`/profiles/${body._id || profile._id}`, {body}, true)

      const update = result.data
      const errs = setErrors(update)
      if (errs) throw errs

      update.profile && setProfile(update.profile)

      if (result.status == 202) {
        let type = false
        const confirms = update.confirms.reduce((acc, k) => (acc = {...acc, ...k}), {})
        const phoneConflict = Object.keys(confirms).includes('phone')
        const emailConflict = Object.keys(confirms).includes('email')
        if (phoneConflict && emailConflict) type = 'both'
        else if (phoneConflict) type = 'phone'
        else if (emailConflict) type = 'email'

        if (type) {
          panel.show('confirm', {isModal: true, type, confirms})
          return false
        } else throw 'Conflicts type error'
      } else {
        return true
      }
    } catch (e) {
      console.log('err.save', e)

      throw e
    }
  }

  return dsl
}

export default Profile
