import React, {createContext, Component} from 'react' // on importe createContext qui servira à la création d'un ou plusieurs contextes
import objectPath from 'object-path'
import Cookiesjs from 'cookies-js'
import Cookies from 'next-cookies'
import Locale from 'locale'
import {handlebars} from '../../lib/utils'
import moment from 'moment'

const {Provider, Consumer} = createContext({})
export const I18nProvider = Provider
export const I18nConsumer = Consumer

class I18nContext extends Component {
  static async getInitialProps(ctx, dicos = {}, options = {}) {
    try {
      const {req, res} = ctx
      const props = {lang: '', dicos, dico: {}, fallback: false}
      const langHeaders = req.headers['accept-language'] || ''

      options = {
        supported: [],
        fallback: '',
        cookie: 'lang',
        ...options
      }

      if (!options.fallback) throw `i18n has no fallback lang set !`

      try {
        let lang = !res
          ? Cookiesjs(options.cookie)
          : Cookies(ctx)[options.cookie] ||
            new Locale.Locales(langHeaders).best(new Locale.Locales(options.supported)).toString()

        // remove region from equation
        if (/_/.test(lang)) lang = lang.split('_')[0]

        props.guess = lang
        props.lang = lang
        if (!options.supported.includes(lang)) throw `lang "${lang}" not supported`
        // console.log('[lang]', props.lang)
      } catch (e) {
        console.log('[lang:fallback]', e)
        props.lang = options.fallback
        props.fallback = true
      }

      props.dico = props.dicos[props.lang]
      props.options = options
      // console.log('init i18n', props.dico)

      return props
    } catch (e) {
      console.log('getInitialProps:err', e)
    }
  }

  constructor(props) {
    super(props)
    const {i18n} = this.props
    this.state = i18n
    this.setLang = this.setLang.bind(this)
    this.t = this.t.bind(this)
  }

  componentDidMount() {
    const {lang, options} = this.state

    this.setLang(lang)

    // set cookie
    if (!Cookiesjs(options.cookie)) Cookiesjs(options.cookie, lang)
  }

  async setLang(lang) {
    const {options, dicos} = this.state

    // load new dico
    const dico = dicos[lang]

    // reset moment locale
    moment.locale(lang)

    // reset cookie
    if (process.browser) Cookiesjs(options.cookie, lang)

    this.setState({lang, dico})
  }

  t(key, data = {}) {
    const {dico} = this.state
    let text = objectPath.get(dico, key)

    // console.log('k', key, text)

    // if data==true, return raw value
    if (data == true) return text

    // if no text, return either key name or undefined if data==false
    if (!text) return data == false ? undefined : `${key}`

    // return interpolated text
    return handlebars(text, data).trim()
  }

  render() {
    const i18nProps = {
      lang: this.state.lang,
      setLang: (lng) => (e) => {
        e.preventDefault()
        this.setLang(lng)
      },
      t: this.t
    }

    return <I18nProvider value={i18nProps}>{this.props.children}</I18nProvider>
  }
}

export const withI18n = (Component) => (props) => (
  <I18nConsumer>{(store) => <Component {...props} {...store} />}</I18nConsumer>
)

export default I18nContext
