const Beacons = ({dsl = {}, profile, page, hasRole} = {}) => {
  dsl.Routes = {
    home: '/',
    ask: '/ask/',
    askMessage: '/ask/message/',
    askChat: '/ask/chat',
    answers: '/answers/',
    previousMessage: '/previous-messages/'
  }

  dsl.available = (t = undefined) => {
    if (dsl.beacons == undefined && t === undefined) return false

    if (t) dsl.beacons = t('helpscout.beacons', true)

    const keys = Object.keys(dsl.beacons || {})

    for (const key of keys) {
      if (hasRole(key) && dsl.beacons[key]) {
        return dsl.beacons[key]
      }
    }

    return false
  }

  dsl.init = (t = undefined) => {
    if (t === undefined) return false
    else {
      dsl.beacons = t('helpscout.beacons', true)
    }

    const beaconId = dsl.available()
    if (beaconId) {
      window.Beacon('init', beaconId)
      dsl.identify()
      dsl.prefill({subject: '', text: ''})
      return true
    }
    return false
  }

  dsl.destroy = () => {
    dsl.logout()
    window.Beacon('destroy')
  }

  dsl.open = () => {
    window.Beacon('open')
  }

  dsl.close = () => {
    window.Beacon('close')
  }

  dsl.toggle = () => {
    window.Beacon('toggle')
  }

  dsl.search = (query) => {
    window.Beacon('search', query)
  }

  dsl.suggest = (docs = []) => {
    window.Beacon('search', docs)
  }

  dsl.article = (article, type = null) => {
    if (type) window.Beacon('article', article, {type})
    else window.Beacon('article', article)
  }

  dsl.navigate = (route) => {
    window.Beacon('navigate', route)
  }

  dsl.identify = () => {
    let name = profile.name

    if ((!name || name.length == 0) && (!profile.email || profile.email.length == 0))
      name = `noname ${profile.phone}`

    window.Beacon('identify', {
      name: name,
      email: profile.email,
      phone: profile.phone,
      slug: page.slug,
      company: page.slug,
      jobTitle: profile.phone
    })
  }

  dsl.prefill = (data) => {
    window.Beacon('prefill', data)
  }

  dsl.resetForm = () => {
    window.Beacon('reset')
  }

  dsl.logout = () => {
    window.Beacon('logout')
  }

  dsl.eventOn = (event, callback) => {
    window.Beacon('on', event, callback)
  }

  dsl.eventOnce = (event, callback) => {
    window.Beacon('once', event, callback)
  }

  dsl.eventOff = (event, callback) => {
    window.Beacon('off', event, callback)
  }

  dsl.hideButton = () => {
    window.Beacon('config', {
      display: {
        style: 'manual',
        iconImage: 'search'
      }
    })
  }

  dsl.showButton = (t) => {
    window.Beacon('config', {
      display: {
        style: 'iconAndText',
        iconImage: 'message',
        text: t('helpscout.cta')
      }
    })
  }

  dsl.changePage = () => {
    const data = {
      type: 'page-viewed',
      url: document.location.href,
      title: document.title
    }

    window.Beacon('event', data), window.Beacon('suggest')
  }

  return dsl
}

export default Beacons
