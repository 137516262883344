import Cookiesjs from 'cookies-js'

const Cookies = ({dsl = {}, prefix = ''} = {}) => {
  dsl.get = (k) => {
    return Cookiesjs(`${prefix}${k}`)
  }

  dsl.set = (k, v, o = {}) => {
    return Cookiesjs.set(`${prefix}${k}`, v, o)
  }

  dsl.remove = (k, o = {}) => {
    return Cookiesjs.expire(`${prefix}${k}`, o)
  }

  return dsl
}

export default Cookies
