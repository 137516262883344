import {asArray} from './utils'
import _ from 'lodash'

const Page = ({
  dsl = {},
  api,
  page,
  setPage,
  setOrders,
  locations,
  setLocations,
  Products
} = {}) => {
  dsl.sync = async (fields) => {
    if (!page.slug) return
    try {
      // fields to sync
      fields = asArray(fields)
      if (!fields.length) throw new Error('fields are required to sync page')

      // clean source for api compare
      const source = {}
      _.each(fields, (f) => (source[f] = page[f]))

      const result = await api.post(`/pages/${page.slug}/sync`, {body: {fields, source}})
      const {sync} = result

      if (sync) setPage({...page, ...sync})
    } catch (e) {
      console.log('sync:err', e)
    }
  }

  dsl.sendInvitations = async (invitations = [], options = {}) => {
    if (!Array.isArray(invitations)) invitations = [invitations]
    if (!invitations.length) throw `No invitations`

    const {canals, sender, isManager} = options
    const body = {invitations, canals, sender, isManager}
    let {wins, fails} = await api.post(`/pages/${page.slug}/invite`, {body})

    return {wins, fails}
  }

  dsl.reloadPage = async () => {
    try {
      let reach = (await api.get(page.slug ? `/pages/${page.slug}/reach` : `/app/reach`, {})) || {}
      setPage(reach.page)
      return reach.page
    } catch (e) {
      return {err: 'Inmemori service unreachable, try again in a few moment please!'}
    }
  }

  dsl.fetchOrders = async (feature) => {
    if (!page || !page.slug) return []
    const results = (await api.get(`/pages/${page.slug}/orders`)) || []
    if (results.err) throw results.err

    setOrders(results)

    return feature ? results.filter((order) => order.feature === 'donation') : results
  }

  dsl.loadLocations = async () => {
    if (!page || !page.slug) return {}
    const results = await api.get(`/locations/${page.slug}`)
    if (results.err) throw results.err

    setLocations(results)
    return results
  }

  dsl.deleteLocation = async (location) => {
    const results = await api.delete(`/locations/${location._id}`)
    if (results.err) throw results.err

    return results
  }

  const resetLocationsFromUpdate = (updatedLocations) => {
    const locationMap = new Map()
    locations.forEach((location) => {
      locationMap.set(location._id, location)
    })

    const updatedLocationsMap = new Map()
    updatedLocations.forEach((location) => {
      updatedLocationsMap.set(location.value._id, location)
    })

    // Remove deleted locations and create new location array
    locations = [
      ...locations.filter((location) => {
        return !!updatedLocationsMap.get(location._id)
      })
    ]

    // Update fulfilled locations from new array
    locations = locations.map((location) => {
      const f = updatedLocationsMap.get(location._id)
      if (f && f.status === 'fulfilled') return f.value
      return location
    })

    // Add missing locations
    updatedLocations.forEach((l) => {
      const f = locationMap.get(l.value._id)
      if (!f) locations.unshift(l.value)
    })

    // Sort locations by date
    locations = _.sortBy(locations, 'meta.date')

    //Update page data
    setLocations(locations)
  }

  dsl.updateLocations = async (newLocations) => {
    if (!page || !page.slug) return {}
    const update = await api.post(`/locations/batch?slug=${page.slug}`, {
      body: {locations: newLocations}
    })

    // Add form value for rejected locations
    let updatedLocations = [...update.results]
    updatedLocations.forEach((result, index) => {
      if (result.status === 'rejected') result.value = newLocations[index]
    })

    // Update page data
    resetLocationsFromUpdate(updatedLocations)
    Products.sync('flower')

    return updatedLocations
  }

  return dsl
}

export default Page
