import {useExperiment, experimentDebugger, emitter} from '@marvelapp/react-ab-test'

const ABTest = ({dsl = {}, analytics, profile, trackEvent} = {}) => {
  dsl.deviceType = () => {
    return analytics && analytics.device && analytics.device.type
  }

  dsl.isMobile = () => {
    return dsl.deviceType() && dsl.deviceType() === 'mobile'
  }

  dsl.isDesktop = () => {
    return dsl.deviceType() && dsl.deviceType() !== 'mobile'
  }

  dsl.initListeners = () => {
    // Called when a 'win' is emitted, in this case by emitter.emitWin('My Example')
    emitter.addWinListener((experimentName, variantName) => {
      trackEvent(`ab-test:${experimentName}:win`, {experimentName, variantName})
      trackEvent(`ab-test:${experimentName}:win`, {experimentName, variantName}, 'profile')
    })
  }

  dsl.defineExperiment = (name, variations = ['A', 'B']) => {
    emitter.defineVariants(name, variations)
    emitter.calculateActiveVariant(name, profile._id)
    return emitter.getActiveVariant(name)
  }

  dsl.useExperiment = (name) => {
    return useExperiment(name)
  }

  dsl.getActiveVariant = (name) => {
    emitter.getActiveVariant(name)
  }

  dsl.emitWin = (name) => {
    emitter.emitWin(name)
  }

  dsl.showDebugger = () => {
    experimentDebugger.enable()
  }

  dsl.hideDebugger = () => {
    experimentDebugger.disable()
  }

  return dsl
}

export default ABTest
