import Handlebars from 'handlebars'
import {parsePhoneNumberFromString} from 'libphonenumber-js/mobile'
import _ from 'lodash'
import {createMuiTheme} from '@material-ui/core'

export const string = {
  isVowel: (str = '') => 'aeiou'.indexOf((str[0] || '').toLowerCase()) !== -1,

  // https://www.question-orthographe.fr/question/le-d-apostrophe-avec-un-nom-propre/
  elision: (str = '') => string.isVowel(str) || str[0].toLowerCase() == 'h',

  normalize: (str = '') => str.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),

  capitalize: (str = '') =>
    str
      .split(' ')
      .map((v) => v.charAt(0).toUpperCase() + (v.slice(1) || '').toLowerCase())
      .join(' '),

  random: (len) => {
    len = len || 9
    let s = '',
      r = 'abcdefghijklmnopqrstuvwxyz'
    for (let i = 0; i < len; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length))
    }
    return s
  },

  // name with lowercased particules
  // d’, de, du, des
  name: (n = '') => {
    n = n.toLowerCase()
    n = string.capitalize(n)
    n = n.replace(/(\sEt\s|\sDe\s|\sDu\s|\sDes\s)/g, (t) => t.toLowerCase()) // first round lowercase
    n = n.replace(/(\sD'|\sL')/g, (t) => t.toLowerCase()) // second round lowercase
    n = n.replace(/(\sd'[a-zé])/g, (t) => ` d'${(t[3] || '').toUpperCase()}`) // apostrophe case (letter d), ex: Jean d’Ormesson
    n = n.replace(/(\sl'[a-zé])/g, (t) => ` L'${(t[3] || '').toUpperCase()}`) // apostrophe case (letter l), ex: Victor de L’Espinay
    n = n.replace(/(-[a-z])/g, (v) => (v || '').toUpperCase()) // tiret case, ex: Jean-albert => Jean-Albert
    n = n.replace(/Née/g, 'née') // Catherine Albertalli Née Larive => Catherine Albertalli née Larive
    n = n.trim()
    return n || undefined
  },

  urlify(str) {
    var urlRegex = /(https?:\/\/[^\s]+)/g
    return str.replace(urlRegex, function (url) {
      return '<a target="_blank" href="' + url + '">' + url + '</a>'
    })
  }
}

export const phoneToData = (input, defaultCountry) => {
  try {
    // country can be passed in phone.country, "phone|fr"
    let phone, country
    if (typeof input == 'object') {
      country = input.country
      phone = input.clean || input.raw || ''
    }
    if (typeof input == 'string') [phone, country] = input.split('|')
    country = (country || defaultCountry || '').trim().toUpperCase()

    if (!phone) return
    const result = parsePhoneNumberFromString(phone, country)
    if (!result || !result.isValid()) return
    if (result.countryCallingCode) result.indicatif = `+${result.countryCallingCode}`
    result.country = (result.country || country).toLowerCase()

    delete result.metadata

    return result
  } catch (e) {
    console.log('utils', 'catch phoneToData', e)
  }
}

export const parsePhone = (phone, country) => {
  phone = phoneToData(phone, country)
  phone = (phone && phone.number) || undefined
  return phone
}

export const formatMoney = (number, symbol = '$', places = 2, thousand, decimal) => {
  thousand = thousand || (symbol == '€' ? '.' : ',')
  decimal = decimal || (symbol == '€' ? ',' : '.')

  let negative = number < 0 ? '-' : ''
  let i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + ''
  let j = i.length
  j = j > 3 ? j % 3 : 0

  let string =
    negative +
    (j ? i.substr(0, j) + thousand : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
    (places
      ? decimal +
        Math.abs(number - i)
          .toFixed(places)
          .slice(2)
      : '')

  if (symbol == '$') string = symbol + string
  else string = string + symbol

  return string
}

export const openInNewTab = (url) => {
  var win = window.open(url, '_blank')
  win.focus()
}

export const timeout = (time = 1000) => new Promise((solv) => setTimeout(solv, time))

export const copy2clipboard = (str) => {
  // Create new element
  var el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = str
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '')
  el.style = {position: 'absolute', left: '-9999px'}
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
}

export const handlebars = (source, data) => {
  const template = Handlebars.compile(source)
  return template(data)
}

const urlencodeJson = (element, key, list) => {
  list = list || []
  if (typeof element == 'object') {
    for (var idx in element) urlencodeJson(element[idx], key ? key + '[' + idx + ']' : idx, list)
  } else {
    element && list.push(key + '=' + encodeURIComponent(element))
  }
  return list.join('&')
}

export const getUrlParams = () => Object.fromEntries(new URLSearchParams(location.search))

export const withQuery = (url, query = {}, returnQueryOnly) => {
  const sign = (u, q) => {
    return Object.keys(q).length ? (/\?/.test(u) ? '&' : '?') : ''
  }
  const q = urlencodeJson(query)
  return returnQueryOnly ? q : `${url}${sign(url, q)}${q}`
}

export const urlQuery = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const utmQuery = (withPrefix = true) =>
  ['source', 'medium', 'campaign', 'content'].reduce((acc, k) => {
    const v = urlQuery(`utm_${k}`)
    if (v) acc[withPrefix ? `utm_${k}` : k] = v
    return acc
  }, {})

export const utmsFromQuery = (query = {}, withPrefix = true) =>
  ['source', 'medium', 'campaign', 'content'].reduce((acc, k) => {
    const v = query[`utm_${k}`]
    if (v) acc[withPrefix ? `utm_${k}` : k] = v
    return acc
  }, {})

export const getUserIp = (req) => {
  let ip =
    req.headers['cf-connecting-ip'] ||
    req.headers['x-forwarded-for'] ||
    (req.connection && req.connection.remoteAddress) ||
    (req.socket && req.socket.remoteAddress) ||
    req.ip ||
    ''

  ip = ip.split(',')[0] || ''
  ip = ip.replace('::ffff:', '')
  if (ip == '::1') ip = 'localhost'
  ip = ip.replace(/ /g, '')

  return ip
}

export const asArray = (input, {delim = ',', trim = true, uniq = true, compact = true} = {}) => {
  let output = typeof input == 'string' ? input.split(delim) : input || []

  if (trim) output = _.map(output, (v) => (typeof v == 'string' ? v.trim() : v))
  if (compact) output = _.compact(output)
  if (uniq) output = _.uniq(output)

  return output
}

export const failValidation = (keys, t, {selector = '.errors-scope', page = {}} = {}) => {
  try {
    keys = asArray(keys)
    const zone = $(selector)
    let errors = {}

    // clear errors
    zone.find('.is-invalid').removeClass('is-invalid')
    zone.find('.err-alert').addClass('d-none')

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      let element = zone.find(`[errkey="${key}"]`)

      // console.log('element', `$("${selector}").find('[errkey="${key}"]')`, element.length)
      if (!element.length) continue
      const type = element.get(0).type
      const value = element.val()
      let errorType = false
      // console.log('element', `$("${selector}").find('[errkey="${key}"]')`, type, value)

      if (type == 'select-one' && value) continue // TODO wtf is type equals select-one, and not just select ?
      if (type == 'select' && value) continue // so we add this line in case it's also just select, untill we have our answer above
      if (type == 'text' && value) continue
      if (type == 'textarea' && value) continue
      errorType = 'missing'

      if (
        type == 'email' &&
        value &&
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      )
        continue
      else if (type == 'email' && value) errorType = 'invalid'

      let alias = element.parent().find(`[erralias="${key}"]`)

      if (alias.length) {
        alias.addClass('is-invalid')
      }

      element.addClass('is-invalid')

      element
        .parent()
        .find('.err-alert')
        .removeClass('d-none')
        .html(t(`common.errors.${errorType}.${key.split('.').pop()}`, page))

      errors[key] = {el: element, value}
    }

    const errKeys = Object.keys(errors)
    errors = errKeys.length ? errors : false

    // focus first err element found
    if (errKeys[0]) errors[errKeys[0]].el.focus()

    return errors
  } catch (e) {
    console.log('failValidation', e)
    return {}
  }
}

export const setErrors = ({errors = {}, err} = {}, {selector = '.errors-scope'} = {}) => {
  try {
    const zone = $(selector)
    const cache = {}

    // clear errors
    zone.find('.is-invalid').removeClass('is-invalid')
    zone.find('.err-alert').addClass('d-none')

    if (err) errors.err = err

    // set errors
    errors = Object.keys(errors).reduce((acc, key) => {
      const path = errors[key].path || key
      const name = /\./.test(path) ? path.split('.')[path.split('.').length - 1] : path
      const message = errors[key].message || errors[key]

      // console.log('setErrors', name, message)
      // console.log('validation.error', path, name)

      // find element to append class
      const byErrkey = zone.find(`[errkey="${name}"]`)
      const byName = zone.find(`[name="${name}"]`)
      // byErrkey take precedence over byName
      const element = byErrkey.length ? byErrkey : byName

      element.addClass('is-invalid')
      if (typeof message == 'string')
        element.parent().find('.err-alert').removeClass('d-none').html(message)

      acc[name] = message
      cache[name] = {message, el: element}
      return acc
    }, {})

    const errKeys = Object.keys(errors)
    errors = errKeys.length ? errors : false

    if (errors) console.log('setErrors:err', errors)

    // focus first err element found
    if (errKeys[0]) cache[errKeys[0]].el.focus()

    return errors
  } catch (e) {
    console.log('setErrors', e)
  }
}

export const scrollTo = (el = 'body', {offset = 0, duration = 200, cond = () => true} = {}) => {
  // console.log('scroll', el, $(el))
  if (cond())
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(el).offset().top + offset
      },
      duration
    )
}

Handlebars.registerHelper('uppercase', (str = '') => str.toUpperCase())
Handlebars.registerHelper('lowercase', (str = '') => str.toLowerCase())
Handlebars.registerHelper('capitalize', (str = '') => str.capitalize())
Handlebars.registerHelper('d_de', (str = '') => (string.elision(str) ? `d'` : `de `))
Handlebars.registerHelper('namize', (str = '') => string.name(str))
Handlebars.registerHelper('equals', function (a, b, opts) {
  return opts[a == b ? 'fn' : 'inverse'](this)
})

Handlebars.registerHelper('maths', (lvalue, operator, rvalue) => {
  lvalue = parseFloat(lvalue)
  rvalue = parseFloat(rvalue)
  return {
    '+': lvalue + rvalue,
    '-': lvalue - rvalue,
    '*': lvalue * rvalue,
    '/': lvalue / rvalue,
    '%': lvalue % rvalue
  }[operator]
})

const reduceOp = function (args, reducer) {
  args = Array.from(args)
  args.pop() // => options
  var first = args.shift()
  return args.reduce(reducer, first)
}

Handlebars.registerHelper({
  eq: function () {
    return reduceOp(arguments, (a, b) => a === b)
  },
  ne: function () {
    return reduceOp(arguments, (a, b) => a !== b)
  },
  lt: function () {
    return reduceOp(arguments, (a, b) => a < b)
  },
  gt: function () {
    return reduceOp(arguments, (a, b) => a > b)
  },
  lte: function () {
    return reduceOp(arguments, (a, b) => a <= b)
  },
  gte: function () {
    return reduceOp(arguments, (a, b) => a >= b)
  },
  and: function () {
    return reduceOp(arguments, (a, b) => a && b)
  },
  or: function () {
    return reduceOp(arguments, (a, b) => a || b)
  }
})

// PROTOTYPES

String.prototype.capitalize = function () {
  return string.capitalize(this)
}

Number.prototype.money = function (...args) {
  const number = this
  return formatMoney(number, ...args)
}
Number.prototype.times100 = function (...args) {
  const number = this
  return parseInt((number * 100).toFixed(0), 10)
}

export const isMobileLayout = () => {
  if (typeof window === 'undefined') return false

  return window.innerWidth < 768
}

export const isDesktopLayout = () => {
  return !isMobileLayout()
}

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

export const materialTheme = createMuiTheme({
  palette: {
    primary: {main: '#32755f'},
    error: {main: '#FF8F60'}
  },
  overrides: {
    MuiRadio: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: '#32755f38 !important'
        },
        '&$checked': {
          color: '#32755f !important'
        },
        '&$checked:hover': {
          backgroundColor: '#32755f38 !important'
        }
      }
    }
  }
})
