import {useState, useMemo} from 'react'

const Memories = ({dsl = {}, page, api}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [pager, setPager] = useState([])

  dsl.list = list
  dsl.isLoading = isLoading
  dsl.pager = pager

  dsl.get = async (id) => {
    const memory = await api.get(`/memories/${id}`)
    return memory
  }

  dsl.hasNext = useMemo(() => !!pager.more, [pager])
  dsl.hasPrev = useMemo(() => pager.page > 1, [pager])
  dsl.hasPager = useMemo(() => dsl.hasNext || dsl.hasPrev, [pager])

  dsl.next = async ({category = 'memory'}) => {
    return await dsl.load({category, index: pager.page + 1})
  }

  dsl.prev = async ({category = 'memory'}) => {
    return await dsl.load({category, index: pager.page - 1})
  }

  dsl.reload = async ({category = 'memory'}) => {
    return await dsl.load(category, pager.page)
  }

  dsl.load = async ({category = 'memory', index = 1} = {}) => {
    if (!page || !page.slug) return []
    setIsLoading(true)
    const type = category == 'memory' ? '1,2,3' : '4,5'

    const results = await api.post(`/memories/lookup?page=${index}`, {
      body: {
        slug: page.slug,
        type
      }
    })

    if (results.data) setList(results.data)
    if (results.pager) setPager(results.pager)

    setIsLoading(false)

    return results
  }

  dsl.delete = async (memory) => {
    const results = await api.delete(`/memories/${memory._id}`)
    if (results.err) throw results.err

    setList(list.filter((m) => m._id !== memory._id))
  }

  return dsl
}

export default Memories
