import _ from 'lodash'

const Flower = ({dsl = {}, page, api, Products}) => {
  const catalog = (Products && Products.feature('flower')) || []

  dsl.catalog = catalog
  dsl.run = Products.run('flower') || {}

  dsl.get = (id) => {
    const flower = _.find(catalog, {_id: id})
    return flower
  }

  dsl.isActive = () => {
    if (page.products.flower.disabled || catalog.length === 0) return false
    return true
  }

  dsl.imagePathFromCatalog = (flower, imageName) => {
    return `central/catalog/${flower.catalog.key}/${imageName}`
  }

  dsl.isItemAvalaibleForLocation = async (itemId, locationId) => {
    return true

    // if (!page.slug || !itemId) return false

    // const body = {id: itemId, locationId}
    // const results = await api.post(`/pages/${page.slug}/central`, {body})

    // return results.run.results == 1
  }

  return dsl
}

export default Flower
