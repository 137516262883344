import moment from 'moment'

const Book = ({dsl = {}, page, Products}) => {
  const product = Products.feature('book') || {}

  dsl.product = product

  dsl.formats = () => {
    return (product && product.formats) || []
  }

  dsl.format = (format) => {
    return (product && product.formats.find((f) => f.name == format)) || false
  }

  dsl.isActive = () => {
    if (page.products.book.disabled || !product) return false
    const cond = moment().diff(page.cat, 'week') >= 1 && page.stats.memories.count > 1
    return cond
  }

  dsl.isVisible = () => {
    if (page.products.book.disabled || !product) return false
    return true
  }

  dsl.getTomesFromFormat = (format) => Math.min(format.replace('x', '').replace('-', '').length, 3)

  return dsl
}

export default Book
