import React from 'react'
import {ToastProvider as TP, ToastConsumer} from 'react-toast-notifications'
import * as ToastsTemplates from '../components/shared/toasts'

export const ToastProvider = TP

export const withToaster = (Comp) =>
  React.forwardRef((props, ref) => {
    return (
      <ToastConsumer>
        {(context) => <Comp toaster={DSL(context, props)} {...props} ref={ref} />}
      </ToastConsumer>
    )
  })

const DSL = (toaster, props) => {
  if (!toaster) return

  toaster.props = props

  toaster.create = (opts) => (Element = opts.element, options) => {
    if (Element.err) Element = Element.err

    // toast only if toast stack if empty (1 toast at a time flow)
    !toaster.toasts.length &&
      toaster.add(Element, {
        autoDismiss: true,
        autoDismissTimeout: 4000,
        className: `bg-snackbar-default bg-rounded`,
        ...opts,
        ...options
      })
  }

  toaster.ok = toaster.create({
    element: ToastsTemplates.ToastDefault({
      toaster,
      ...props,
      label: props.t('snackbar.default.ok')
    })
  })
  toaster.success = (message = props.t('snackbar.default.success')) =>
    toaster.create({
      element: ToastsTemplates.ToastDefault({toaster, ...props, label: message})
    })()
  toaster.error = (message = props.t('snackbar.default.error')) =>
    toaster.create({
      element: ToastsTemplates.ToastDefault({toaster, ...props, label: message}),
      className: 'bg-rounded bg-error'
    })()
  toaster.help = toaster.create({
    element: ToastsTemplates.ToastHelp({toaster, ...props}),
    autoDismiss: false
  })
  toaster.inviteManager = (id) =>
    toaster.create({
      element: ToastsTemplates.ToastInviteManager({toaster, ...props, id}),
      autoDismissTimeout: 8000
    })()

  return toaster
}

export const ToastContainer = (props) => {
  const {children, placement} = props
  return <div className={`toasty-container ${placement}`}>{children}</div>
}

const snackStates = {
  entering: {transform: 'translate3d(0, 120%, 0) scale(0.9)'},
  entered: {transform: 'translate3d(0, 0, 0) scale(1)'},
  exiting: {transform: 'translate3d(0, 120%, 0) scale(0.9)'},
  exited: {transform: 'translate3d(0, 120%, 0) scale(0.9)'}
}

export const Toast = ({className = '', children, transitionDuration, transitionState}) => {
  return (
    <div
      ref={(element) => {
        if (element) element.style.setProperty('box-shadow', 'none', 'important')
      }}
      style={{
        minWidth: 300,
        pointerEvents: 'initial',
        transitionProperty: `transform`,
        transitionDuration: `${transitionDuration}ms`,
        transitionTimingFunction: `cubic-bezier(0.2, 0, 0, 1)`,
        transformOrigin: 'bottom',
        zIndex: 2,
        ...snackStates[transitionState]
      }}
      className={`noselect ${className}`}
    >
      <div>{children}</div>
    </div>
  )
}
