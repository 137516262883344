export const FlowStep = {
  default: 'tab-infos',

  tabInfos: 'tab-infos',
  tabMemories: 'tab-memories',
  tabCeremony: 'tab-ceremony',
  tabShare: 'tab-share',
  tabSettings: 'tab-settings',
  tabHelp: 'tab-help',

  ftFlower: 'ft-flower',
  ftFlowers: 'ft-flowers',
  ftLetter: 'ft-letter',
  ftBook: 'ft-book',
  ftDonation: 'ft-donation',
  ftDonationSettings: 'ft-donation-settings',
  donators: 'donators',

  onboarding: 'onboarding',
  splash: 'splash',

  editIntro: 'edit-intro',
  editProfile: 'edit-profile',
  editPlaces: 'edit-places',
  editBroadcast: 'edit-broadcast',

  profile: 'profile',
  profileSetup: 'profile-setup',

  unsubscribe: 'unsubscribe',
  stopAssistance: 'stop-assistance',

  newMemory: 'new-memory',
  managers: 'managers',
  manageNotifs: 'manage-notifs',
  swap: 'swap',
  confirm: 'confirm',
  accessConfirm: 'access-confirm',
  logout: 'logout',
  invitations: 'invitations',
  thank: 'thank',

  prev: 'prev',
  next: 'next'
}
