import React, {useState} from 'react'
import Modal from '../misc/modal'

export const usePanel = () => {
  const defaultOptions = {isModale: false}
  const defaultState = {current: null, options: defaultOptions, hidden: true}
  const [state, setState] = useState(defaultState)

  const show = (panel, options = {isModale: false}) => {
    $('html,body').addClass('screenblock')
    setState({current: panel, options, hidden: false})
  }

  const hide = () => {
    $('html,body').removeClass('screenblock')
    setState(defaultState)
  }

  const dsl = {
    current: state.current,
    hidden: state.hidden,
    options: state.options,
    show,
    hide
  }

  return dsl
}

const renderPanel = (panels, panel, opts) => {
  if (!panels[panel]) return null

  const renderedPanel = React.createElement(panels[panel], {key: name, ...opts})
  return renderedPanel
}

const Panel = ({panels = [], panel}) => {
  if (panel.hidden || !panel.current || !panels[panel.current]) return null

  if (panel.options && panel.options.isModal)
    return (
      <Modal
        isOpen
        maxWidth='800px'
        onClose={() => {
          panel.hide()
        }}>
        {renderPanel(panels, panel.current, panel.options)}
      </Modal>
    )

  return (
    <div className='panel'>
      {renderPanel(panels, panel.current)}

      <style jsx global>
        {`
          .panel {
            background: #fff;
            z-index: 5000;
            height: 100vh;
            width: 100vw;
            overflow: scroll;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
        `}
      </style>
    </div>
  )
}

export default Panel
