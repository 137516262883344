import {withQuery} from '../lib/utils'

const Letter = ({dsl = {}, page, Products}) => {
  const product = Products.feature('letter') || {}

  const baseFetchUrl = 'https://res.cloudinary.com/inmemori/image/fetch'

  dsl.product = product

  dsl.generateSlide = (slide, config = {}) => {
    const configCopy = {...config}
    delete configCopy.files

    const apiUrl = withQuery(
      process.env.APP_ENV === 'local'
        ? `http://api.inmemori-dev.com/pdf/letter/slide`
        : `${page.uri.api}/pdf/letter/slide`,
      {
        slug: page.slug,
        slide,
        ...configCopy
      }
    )
    return `${baseFetchUrl}/c_fill,g_center,w_1275,h_895,f_auto,fl_lossy/${encodeURIComponent(
      apiUrl
    )}`
  }

  dsl.slides = (config, t) => {
    return [1, 2, 3, 4].map((index) => {
      return dsl.generateSlide(index, config[`slide${index}`], t)
    })
  }

  dsl.defaultImages = (t) => {
    return [
      dsl.generateSlide(1, {mode: 'icon', value: 'flower'}, t),
      dsl.generateSlide(
        1,
        {mode: 'cover', value: 'flower', value2: page.defunct.image || 'nopic'},
        t
      ),
      dsl.generateSlide(
        2,
        {
          mode: 'text',
          value: t('ft-letter.intro.contentExample', page)
        },
        t
      ),
      dsl.generateSlide(3, {mode: 'image', value: 'letter/letter-last-cover-front-default'}, t),
      dsl.generateSlide(4, {mode: 'empty', value: false}, t)
    ]
  }

  dsl.slideStatus = (slide, config) => {
    const data = config[`slide${slide}`]

    switch (slide) {
      case 1: {
        if (data && data.mode) {
          if (data.mode === 'icon' && data.value) return 'on'
          if (data.mode === 'cover' && data.value && data.value2) return 'on'
          // return 'error'
        }
        return 'off'
      }
      case 2:
        return data && !!data.value ? 'on' : 'off'
      case 3: {
        if (data && data.mode) {
          if (data.mode === 'empty') return 'on'
          if (data.mode === 'image' && data.value) return 'on'
          // return 'error'
        }
        return 'off'
      }
      case 4: {
        if (data && data.mode) {
          if (data.mode === 'empty') return 'on'
          if (data.mode === 'address' && data.value) return 'on'
          // return 'error'
        }
        return 'off'
      }
      default:
        return 'off'
    }
  }

  dsl.icons = [
    {key: 'flower', path: '/letter/pictos/svg/flower'},
    {key: 'branch', path: '/letter/pictos/svg/branch'},
    {key: 'bird', path: '/letter/pictos/svg/bird'},
    {key: 'none'}
  ]

  dsl.isActive = () => {
    if (page.products.letter.disabled || !product) return false
    return true
  }

  dsl.settings = () => {
    return [
      {
        index: 1,
        preview: true,
        modes: {
          cover: {
            aspectRatio: 172 / 217,
            preview: true
          },
          icon: {
            preview: true
          }
        }
      },
      {
        index: 2,
        preview: true,
        modes: {
          text: {}
        }
      },
      {
        index: 3,
        preview: true,
        modes: {
          image: {
            preview: true,
            aspectRatio: 340 / 218
          },
          empty: {preview: false}
        }
      },
      {
        index: 4,
        modes: {
          address: {preview: true},
          empty: {preview: true}
        },
        preview: true
      }
    ]
  }

  return dsl
}

export default Letter
