import React, {useEffect} from 'react'
import Head from 'next/head'
import {withGlobal} from '../shared/app'
import AppIcons from '../misc/app-icons'

const OpenGraph = ({og}) => (
  <React.Fragment>
    {og.name && <meta property='og:site_name' content={`${og.name}`} />}
    {og.url && <meta property='og:url' content={`${og.url}`} />}
    {og.title && <meta property='og:title' content={`${og.title}`} />}
    {og.description && <meta property='og:description' content={og.description} />}
    {og.type && <meta property='og:type' content={`${og.type}`} />}
    {og.locale && <meta property='og:locale' content={og.locale} />}
    {og.image && <meta property='og:image' content={og.image} />}
  </React.Fragment>
)

export default withGlobal(({query, bg = '', className = '', style, children, page, opengraph}) => {
  const backgroundColor = bg

  useEffect(() => {
    if (['logout', 'swap'].includes(query.flow)) return
  }, [])

  return (
    <React.Fragment>
      <OpenGraph og={opengraph} />
      <AppIcons path='/img/brand' />

      <Head>
        <title>{page.fullname || 'Inmemori'}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />

        <link
          href='https://res.cloudinary.com/inmemori/image/upload/CRM/img/favicon32.png'
          rel='icon'
          type='image/png'
        />
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css'
          integrity='sha256-2XFplPlrFClt0bIdPgpz8H7ojnk10H69xRqd9+uTShA='
          crossOrigin='anonymous'
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
          analytics.load("${process.env.SEGMENT_KEY}");
        }}();
      `
          }}
        />

        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`
          }}
        />

        <script src='https://cdn.polyfill.io/v3/polyfill.js?features=Object.assign,Element.prototype.matches,Element.prototype.attachShadow,Object.entries,Array.prototype.includes,Array.find,Array.findIndex,Array.from,Array.includes,Array.some,Array.every,CustomEvent,Object.keys'></script>

        <script
          src='https://code.jquery.com/jquery-3.5.1.min.js'
          integrity='sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0='
          crossOrigin='anonymous'></script>
      </Head>

      <div className={`layout with-footer ${backgroundColor} ${className}`} style={style}>
        {children}
      </div>

      {/*<ModalVideo channel='youtube' isOpen={video} videoId={t('menu.video-id')} onClose={e=>setVideo(false)} />*/}
      <script
        src='https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js'
        integrity='sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo'
        crossOrigin='anonymous'></script>
      <script
        src='https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js'
        integrity='sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6'
        crossOrigin='anonymous'></script>
      <script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GMAP_KEY}&libraries=places`}></script>
      <script type='text/javascript' src='https://unpkg.com/colcade@0/colcade.js'></script>
      <script
        src='https://cdnjs.cloudflare.com/ajax/libs/history.js/1.8/native.history.min.js'
        integrity='sha256-rRsRywU99OjLiQhy+kolBX1RSowneLa3RcWq9bP5hM0='
        crossOrigin='anonymous'></script>
    </React.Fragment>
  )
})
