const Donation = ({dsl = {}, page, hasRole, Products}) => {
  const product = Products.feature('donation') || {}

  dsl.product = product

  dsl.isActive = () => {
    if (page.products.donation.disabled || !product) return false

    const isManager = hasRole('manager')
    if (isManager && [0, 1].includes(page.donation.state)) return true
    if (!isManager && [1].includes(page.donation.state)) return true
    return false
  }

  return dsl
}

export default Donation
