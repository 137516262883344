import {useState} from 'react'
import Flower from './flower'
import Letter from './letter'
import Book from './book'
import Donation from './donation'
import _ from 'lodash'

const Products = ({dsl = {}, page, api, hasRole}) => {
  const [loaded, setLoaded] = useState(false)
  const [products, setProducts] = useState({flower: {}, donation: {}, book: {}, letter: {}})

  dsl.products = products
  dsl.setProducts = setProducts

  dsl.features = () => Object.keys(products).filter((feature) => dsl.featureActive(feature))

  dsl.feature = (feature) => {
    if (dsl.featureActive(feature)) {
      const ret = [...products[feature].results]

      if (ret.length == 1) return ret[0]
      else return ret
    }
    return false
  }

  dsl.sorted = (feature, filter, order = 'asc') => {
    if (dsl.featureActive(feature)) {
      const ret = [...products[feature].results]
      if (ret.length == 1) return ret[0]
      else {
        if (order === 'asc') {
          return ret.sort((a, b) => a[filter] - b[filter])
        } else if (order === 'desc') {
          return ret.sort((a, b) => b[filter] - a[filter])
        } else return ret
      }
    }
    return false
  }

  dsl.run = (feature) => {
    if (dsl.featureActive(feature)) {
      return products[feature].run
    }

    return {}
  }

  dsl.featureActive = (feature) => {
    return products[feature] && products[feature].results && !!products[feature].results.length
  }

  dsl.load = async (global = false) => {
    if (global) {
      let results = await api.get(`/products`)

      results = {
        ..._.uniqBy(results, 'feature')
          .map((product) => product.feature)
          .reduce(
            (o, key) => ({
              ...o,
              [key]: {results: results.filter((product) => product.feature === key)}
            }),
            {}
          )
      }
      setProducts(results)
      setLoaded(true)

      return results
    }

    if (!page || !page.slug) return products

    const results = await api.post(`/pages/${page.slug}/products`)

    setProducts(results)
    setLoaded(true)

    return results
  }

  dsl.sync = async (feature) => {
    if (!page || !page.slug || !loaded) return products

    const results = await api.post(`/pages/${page.slug}/products?feature=${feature}`)

    const newProducts = {...products}
    newProducts[feature] = {...results[feature]}
    setProducts(newProducts)
  }

  dsl.Book = Book({page, Products: dsl})
  dsl.Flower = Flower({page, api, Products: dsl})
  dsl.Letter = Letter({page, api, Products: dsl})
  dsl.Donation = Donation({page, api, Products: dsl, hasRole})

  return dsl
}

export default Products
