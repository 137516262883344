import _ from 'lodash'

// Cloudinary

const futch = (url, opts = {}, onProgress) =>
  new Promise((solve, ject) => {
    var xhr = new XMLHttpRequest()
    xhr.open(opts.method || 'get', url)
    for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

    xhr.onerror = ject
    xhr.onload = (e) => solve(JSON.parse(e.target.responseText))

    if (xhr.upload)
      xhr.upload.onprogress = (e) => {
        const progress = Math.round((e.loaded / e.total) * 100)
        onProgress && onProgress(progress, e)
      }

    xhr.send(opts.body)
  })

const Cloudinary = ({mock, mockId, presets = {}} = {}) => {
  const dsl = {}

  dsl.url = (id, {fallbackUrl = 'nopic', filter = ''} = {}) => {
    return mock || !id
      ? /(^http|^https)/.test(fallbackUrl)
        ? fallbackUrl
        : /$\//.test(fallbackUrl)
        ? fallbackUrl
        : `https://res.cloudinary.com/inmemori/image/upload/${
            filter ? `${filter},` : ''
          }f_auto,fl_lossy/${fallbackUrl}`
      : /(^http|^https)/.test(id)
      ? id
      : `https://res.cloudinary.com/inmemori/image/upload/${
          filter ? `${filter},` : ''
        }f_auto,fl_lossy/${id}`
  }

  dsl.uploadFile = async (file, {preset, onProgress} = {}) =>
    new Promise(async (solve, ject) => {
      try {
        const formData = new FormData()
        if (presets[preset]) formData.append('upload_preset', presets[preset])
        if (file) formData.append('file', file)
        else throw new Error('file is required')

        const result = mock
          ? {public_id: mockId}
          : await futch(
              `https://api.cloudinary.com/v1_1/inmemori/image/upload`,
              {method: 'post', body: formData},
              onProgress
            )

        solve(result)
      } catch (e) {
        console.log('uploadFile:err', e)
        ject(e)
      }
    })

  dsl.upload = async (files, options) => {
    if (files.constructor.name == 'FileList') files = Object.values(files)
    files = Array.isArray(files) ? files : [files]

    const results = await Promise.allSettled(files.map((file) => dsl.uploadFile(file, options)))
    const wins = _.filter(results, {status: 'fulfilled'}).map((p) => p.value)
    const fails = _.filter(results, {status: 'rejected'}).map((p) => p.value)
    return {wins, fails}
  }

  // this method need to be signed
  dsl.download = async () => {
    const url = `https://api.cloudinary.com/v1_1/inmemori/image/generate_archive`

    const formData = new FormData()
    formData.append('mode', 'download')
    formData.append('public_ids', 'localhost_rezwzc')

    try {
      return await (await fetch(url, {method: 'post', body: formData})).json()
    } catch (e) {
      return {}
    }
  }

  dsl.filepond = (preset) => ({
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      const url = `https://api.cloudinary.com/v1_1/inmemori/image/upload`
      const xhr = new XMLHttpRequest()
      const formData = new FormData()

      xhr.open('POST', url, true)
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

      xhr.upload.addEventListener('progress', (e) => {
        progress(e.lengthComputable, e.loaded, e.total)
      })

      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
          return
        }

        if (xhr.status >= 200 && xhr.status < 300) {
          const response = JSON.parse(xhr.responseText)
          load({
            type: 'load',
            code: 200,
            body: {hash: response.public_id, width: response.width, height: response.height},
            headers: {}
          })
          // load(response.public_id)
          return
        }

        error(`Error: ${xhr.statusText}`)
      }

      if (presets[preset]) formData.append('upload_preset', presets[preset])
      formData.append('file', file)

      if (mock) {
        load(mockId)
      } else {
        xhr.send(formData)
      }
      return {
        abort: () => {
          abort()
          xhr.abort()
        }
      }
    },
    load: (source, load, error, progress, abort) => {
      fetch(dsl.url(source))
        .then((resp) => resp.blob())
        .then((blob) => {
          load(blob)
        })
        .catch((e) => error(e))

      return {
        abort: () => {
          abort()
        }
      }
    }
  })

  return dsl
}

export default Cloudinary
