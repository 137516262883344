import {useState} from 'react' // on importe createContext qui servira à la création d'un ou plusieurs contextes
import _ from 'lodash'

const SCENARIOS = {
  's-manager': ['has-invited', 'has-photo', 'check-places', 'check-intro'],
  's-google': ['has-invited', 'has-photo', 'check-places', 'check-intro'],
  's-user': ['has-published', 'has-invited']
}

const BANNERS = {
  'check-intro': {
    onCta: ({banner}) => {
      banner.clear(true)
    },
    onCtb: ({flow}) => flow.set('edit-intro'),
    block: 'card-intro',
    visible: () => true,
    helper: true
  },
  'check-places': {
    onCta: ({flow}) => {
      flow.set('edit-places')
    },
    onCtb: ({banner}) => banner.clear(true),
    block: 'card-places',
    visible: () => true,
    helper: true
  },
  'has-photo': {
    onCta: ({flow}) => flow.set('edit-profile'),
    onCtb: ({banner}) => {
      banner.clear()
    },
    visible: () => true,
    helper: true
  },
  'has-invited': {
    onCta: ({banner, flow}) => {
      flow.set('tab-share')
      banner.clear()
    },
    onCtb: ({banner}) => {
      banner.clear()
    },
    visible: () => true,
    helper: false
  },
  'has-published': {
    onCta: ({banner, flow}) => {
      flow.set('tab-memories')
      banner.clear()
    },
    onCtb: ({banner}) => {
      banner.clear()
    },
    helper: false
  },
  'open-collect': {
    onCta: ({banner, flow}) => {
      flow.set('ft-donation-settings')
      banner.clear()
    },
    onCtb: ({banner}) => {
      banner.clear()
    },
    block: 'card-ft-donation',
    visible: () => true,
    helper: true
  },
  'has-thank': {
    onCta: ({banner, flow}) => {
      flow.set('tab-memories', {thankHelper: true})
      banner.clear()
    },
    onCtb: ({banner}) => {
      banner.clear()
    },
    visible: () => {
      return false
      // const {profile, memoriesIndex} = ctx
      // const filteredMemories = memoriesIndex['memory'].filter(
      //   (memory) => !memory.profile || memory.profile !== profile._id
      // )
      // return filteredMemories.length > 0
    },
    helper: false
  },
  'post-event': {
    onCta: ({banner, flow}) => {
      flow.set('tab-memories')
      banner.clear()
    },
    onCtb: ({banner}) => {
      banner.clear()
    },
    visible: () => true,
    helper: false
  }
}

const getBannersFromScenario = (scenario, checklist) => {
  const scenes = SCENARIOS[scenario] || []
  const {t, page} = ctx

  const banners = _.map(scenes, (key) => {
    const rawbanner = BANNERS[key]
    const i18nKey = `banners.${scenario}|${key}`
    const specs = t(i18nKey, true)
    const text = t(`${i18nKey}.text`, page)
    const cleared = !!checklist[key]

    const scene = {id: key, ...rawbanner, ...specs, text, cleared}
    return scene
  })

  return banners.filter((b) => !b.cleared)
}

const guessScenario = () => {
  const {page, profile, tribe} = ctx

  const scenario = tribe.isManager ? (page.partner._id ? 's-manager' : 's-google') : 's-user'

  const checklist = tribe.isManager ? page.checklist : profile.checklist || {}

  return [scenario, checklist]
}

const initProcess = () => {
  const [scenario, checklist] = guessScenario()
  const banners = getBannersFromScenario(scenario, checklist)
  return [banners, scenario, checklist]
}

const getCrossedList = (banners) =>
  banners
    .filter((b) => b.crossed)
    .reduce((acc, banner) => {
      acc[banner.id] = true
      return acc
    }, {})

let inited = false
let banners = []
let ctx

export const useBanners = () => {
  const [, setBanners_] = useState(banners || [])
  const [banner, setBanner] = useState(false)
  const [currentIndex, setCurentIndex] = useState(-1)

  const crosscheck = async () => {
    try {
      const checklist = getCrossedList(banners)
      // console.log('crosscheck', checklist, banners)

      if (!Object.keys(checklist).length) return

      const update = await ctx.api.put(`/pages/${ctx.page.slug}/checklist`, {
        body: {checklist}
      })
      if (update.err) throw update.err
    } catch (e) {
      console.log('err.checklist', e)
    }
  }

  const setBanners = (scenes) => {
    let newBanners = _.map(scenes, (scene) => {
      // bind clear method to banner
      if (!scene.clear)
        scene.clear = function (crossed) {
          scene.cleared = true
          if (crossed) scene.crossed = true

          // trigger next
          const hasNext = setNext() > -1
          // end of flow, trigger crosscheck update
          if (!hasNext) crosscheck()
        }

      return scene
    })
    // console.log('set banners', newBanners.length)
    banners = newBanners
    setBanners_(newBanners)
  }

  const init = (context) => {
    if (inited) return
    ctx = context
    inited = true
    const [scenes] = initProcess()
    setBanners(scenes)
  }

  const setNext = () => {
    const find = banners.find((b) => !b.cleared)
    const index = banners.map((b) => b.id).indexOf(find && find.id)
    setBanner(find || false)
    setCurentIndex(index)
    return index
  }

  const updateBanner = (id, update = {}) => {
    const index = _.findIndex(banners, {id})
    // console.log('updateBanner', id, update, index, banners)
    if (index >= 0) {
      banners[index] = {...banners[index], ...update}
      setBanners([...banners])
    }
    return banners
  }

  const getCurrent = (id) => {
    if (!banner) return
    if (id) return banner.id == id ? banner : false
    return banner
  }

  const clearBanner = (id) => {
    updateBanner(id, {cleared: true, crossed: true})
    crosscheck()
    setNext()
  }

  const Service = {
    init,
    inited,
    banners,
    banner,
    setBanner,
    updateBanner,
    clearBanner,
    getCurrent,
    currentIndex,
    setNext
  }

  return Service
}
