import Cookies from './cookies'

const Auth = ({dsl = {}, api} = {}) => {
  const cookies = Cookies({prefix: 'im_web_'})

  dsl.getJWT = () => cookies.get('jwt')

  dsl.setJWT = (jwt) => {
    // set cookies
    const old = dsl.getJWT()
    const hasChanged = jwt != old

    if (hasChanged) {
      cookies.set('jwt', jwt, {expires: Infinity})
      if (api) api.options.creds.jwt = jwt // live update api instance jwt
    }
    return hasChanged
  }

  dsl.logout = async ({reload, redirect} = {}) => {
    dsl.setJWT(undefined)

    reload && window.location.reload(true)
    redirect && window.location.replace(redirect)
  }

  dsl.login = async (jwt, {reload, redirect} = {}) => {
    if (!jwt || typeof jwt != 'string') return false

    dsl.setJWT(jwt)

    // make sur cookie had time to be set before reloading
    setTimeout(() => {
      reload && window.location.reload(true)
      redirect && window.location.replace(redirect)
    }, 800)

    return jwt
  }

  dsl.createApikey = async (claim, tmp = true) => {
    const apikey = await api.post(`/apikeys`, {body: {...claim, tmp}, thraw: true})
    return apikey
  }

  dsl.logAs = async (claim, {reload, redirect} = {}) => {
    const jwt = typeof claim != 'string' ? (await dsl.createApikey(claim)).token : claim

    console.log('[auth:logas]', claim, jwt)
    if (jwt) dsl.login(jwt, {reload, redirect})

    return jwt
  }

  return dsl
}

export default Auth
