import React from 'react'
import Modal from 'react-modal'

const IMModal = ({isOpen = true, onClose = () => {}, children, maxWidth = '600px'}) => {
  const close = () => {
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      className='modalz'
      overlayClassName='overlayz'
      onRequestClose={close}
      ariaHideApp={false}>
      {children}

      <style jsx global>
        {`
          .modalz {
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            max-width: ${maxWidth};
            max-height: 100%;
            position: absolute;
            display: block;
            overflow: scroll;
            outline: none;
          }

          @media (max-width: 605px) {
            // sm down
            .modalz {
              max-width: 360px;
            }
          }

          .overlayz {
            z-index: 5000;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);
          }

          .ReactModal__Body--open {
            overflow: hidden;
          }
        `}
      </style>
    </Modal>
  )
}

export default IMModal
