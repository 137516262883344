import React, {useState, useEffect} from 'react'

// used as cache for storing props on render
const propsByBlock = {}

export const useFlow = ({
  startFlow,
  defaultFlow,
  urlPrefix,
  navstory,
  setNavstory,
  startProps = {},
  trackEvent = () => {},
  lastUIRef = {},
  setLastUIRef = () => {}
}) => {
  const [current, setCurrent] = useState(startFlow || defaultFlow)
  const [navIndex, setNavIndex] = useState(0)
  const [prev, setPrev] = useState(defaultFlow)
  const [prevScroll, setPrevScroll] = useState(0)

  //initialized start flow with start props
  if (!propsByBlock[startFlow]) propsByBlock[startFlow] = startProps

  useEffect(() => {
    setNavstory([[current, {init: true}, 0]])
  }, [])

  const set = (flow, props = {}) => {
    const isPrev = flow == 'prev'

    if (isPrev) {
      // assign flow to prev
      flow = prev || ''

      // if same flow, redirect to defaultFlow
      if (flow == current) flow = defaultFlow
    }

    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop

    const state = {
      flow,
      props,
      index: History.getCurrentIndex() + 1, //we +1 because we init navstory with first flow, so there's an off by one counter
      ...(isPrev && {isPrev}),
      scroll: {current: currentScroll, prev: prevScroll}
    }

    History.pushState(state, null, `${urlPrefix}/${flow}`)

    // current get assigned to prev
    setPrev(current)
    setPrevScroll(currentScroll)

    // cache some data
    propsByBlock[flow] = props
    setNavIndex(navIndex + 1)
    setNavstory([[flow, props, navIndex + 1], ...navstory])

    //analytics
    if (props.uiref) {
      setLastUIRef(props.uiref)
      // trackEvent(`flow:set`, {flow, uiref: props.uiref}, 'profile')
    }
  }

  const dsl = {
    current,
    setCurrent,
    navIndex,
    setNavIndex,
    prev,
    set,
    propsByBlock
  }

  return dsl
}

const renderFlow = (flows, block = '') => {
  // if provided array, map & render
  if (Array.isArray(block)) return block.map((b) => renderFlow(flows, b))

  // clean key
  const name = block.toLowerCase()

  if (!flows[name]) return <div>Block {block} has not been created yet.</div>

  // block can reference group of blocks
  if (Array.isArray(flows[name])) return flows[name].map((b) => renderFlow(flows, b))

  const blockProps = propsByBlock[name] || {}

  const renderedFlow = React.createElement(flows[name], {...blockProps, key: name})
  return renderedFlow
}

const Flow = ({flows = [], flow}) => {
  return (
    <div id={flow} className='target-flow'>
      {renderFlow(flows, flow)}
      <style jsx>
        {`
          .target-flow.fullscreen {
            background: #fff;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        `}
      </style>
    </div>
  )
}

export default Flow
